html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 0px;
}

.progress-ring__circle {
  transition: 2s stroke-dashoffset;
  transform: rotate(-90deg);
}